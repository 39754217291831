import React from "react"
import { graphql } from "gatsby"
import ServiceList from "../components/ServiceList/ServiceList"
import Heading from "../components/Heading/Heading"
import styles from "./pages.module.scss"
import Header from "../components/Header/Header"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import SEO from "../components/seo"
import Footer from "../components/Footer/Footer"
import HeaderImg from "../components/HeaderImg/HeaderImg"

export default function servizi({ data, transitionStatus }) {
  let transitionClass = "transitionContents " + transitionStatus
  const meta = data.serviziArchive.edges[0].node.yoast

  return (
    <TransitionBehavior>
      <div className={transitionClass}>
        <Header />
        <SEO meta={meta} />
        <HeaderImg tipoheader={"testataStandardPullUp"}>
          <Heading
            title={"Servizi"}
            subtitle={"Expertise in costante evoluzione"}
            ish1={true}
          ></Heading>
        </HeaderImg>
        <div className={styles.containerMedium}>
          <ServiceList serviceArray={data.servizi} />
        </div>
        <Footer />
      </div>
    </TransitionBehavior>
  )
}

export const query = graphql`
  {
    serviziArchive: allWordpressPage(filter: { slug: { eq: "servizi" } }) {
      edges {
        node {
          yoast {
            canonical
            focuskw
            linkdex
            meta_robots_adv
            meta_robots_nofollow
            meta_robots_noindex
            metadesc
            metakeywords
            opengraph_description
            opengraph_image
            opengraph_title
            redirect
            title
            twitter_description
            twitter_image
            twitter_title
          }
        }
      }
    }
    servizi: allWordpressWpServizio {
      edges {
        node {
          acf {
            header {
              localFile {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
            __typename
            descrizione
            titolo
            gruppo_servizi {
              servizio
            }
          }
          slug
        }
      }
    }
  }
`
