import React from "react"
import style from "./GruppoServizi.module.scss"

export default function GruppoServizi({ gruppo }) {
  return (
    <div className={style.servizi}>
      {gruppo.map(servizio => {
        return (
          <div>
            <h2>{servizio.servizio}</h2>
          </div>
        )
      })}
    </div>
  )
}
