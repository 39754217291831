import React from "react"
import style from "./ServiceList.module.scss"
import AnimatedLink from "../AnimatedLink/AnimatedLink"
import Heading from "../Heading/Heading"
import Img from "gatsby-image/withIEPolyfill"
import GruppoServizi from "./GruppoServizi/GruppoServizi"

export default function ServiceList({ serviceArray }) {
  return (
    <div>
      {serviceArray.edges.map(({ node: servizio }) => {
        const imgFluid = servizio.acf.header.localFile.childImageSharp.fluid
        const titolo = servizio.acf.titolo
        const gruppoServizi = servizio.acf.gruppo_servizi
        return (
          <div key={servizio.id} className={style.projectList}>
            <div className={style.projectListItem}>
              <div className={style.projectListItemRow}>
                <div className={style.imageContainer}>
                  <AnimatedLink to={`/servizi/${servizio.slug}`}>
                    <Img
                      fluid={imgFluid}
                      className={style.fillImageForceful}
                      alt={titolo}
                    />
                  </AnimatedLink>
                </div>

                <div className={style.titleContainer}>
                  <AnimatedLink to={`/servizi/${servizio.slug}`}>
                    <Heading title={titolo} ish1={false} alignment={"left"}>
                      <GruppoServizi gruppo={gruppoServizi} />
                    </Heading>
                  </AnimatedLink>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
